/* eslint-disable */

import React, { useEffect, useState } from "react";
import Signature from '../../images/royalmaster-signature.png'
import Swal from "sweetalert2";

const formatToNaira = (amount) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(amount);
};

function PrintReceipt() {
  const [tableData, setTableData] = useState([]);
  const [to, setTo] = useState("");
  const [amountInWords, setAmountInWords] = useState("");

  useEffect(() => {
    // Retrieve the stored data from localStorage
    const storedData = JSON.parse(localStorage.getItem("productTableData"));
    if (storedData) {
      // Assuming that "tableData" is an array within the stored data
      if (
        Array.isArray(storedData.tableData) &&
        storedData.tableData.length > 0
      ) {
        setTableData(storedData.tableData);
      }

      // Extract other fields from the stored data
      setTo(storedData.to);
      setAmountInWords(storedData.amountInWords);
    }
  }, []);

  const calculateTotalPrice = () => {
    return tableData.reduce((total, row) => total + row.value, 0);
  };

  const handlePrintEmail = () => {
    // Include your email sending logic here using SMTP.js
    window.print();
    const productTableData = JSON.parse(
      localStorage.getItem("productTableData")
    );

    if (Array.isArray(tableData) && tableData.length > 0) {
      const emailBody = `
      <div className="row">
      <div className="col-12 col-md-12">
      <h3>To: ${to} </h3>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Product Description</th>
              <th>Quantity</th>
              <th>Price per Unit (₦)</th>
              <th>Value (₦)</th>
            </tr>
          </thead>
          <tbody>
            ${tableData
              .map(
                (row, index) => `
                <tr key=${index}>
                  <td>${row.productName}</td>
                  <td>${row.productDescription}</td>
                  <td>${row.quantity}</td>
                  <td>${formatToNaira(row.pricePerUnit)}</td>
                  <td>${formatToNaira(row.value)}</td>
                </tr>`
              )
              .join("")}
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <strong>Total Price:</strong> ${formatToNaira(calculateTotalPrice())}
      <h3>Amount In Words: ${amountInWords}</h3>
    </div>
    `;

      Email.send({
        SecureToken: "1391a2e8-b8ba-458d-9837-5b3eac904d31",
        To: "bernardadaora@gmail.com",
        From: "bernardadaora@gmail.com",
        Subject: "Receipt Details",
        Body: emailBody,
      }).then((message) => {
        if (message === "OK") {
          // Email sent successfully
          Swal.fire({
            icon: 'success',
            title: 'Receipt Sent',
            text: 'Receipt email sent successfully.',
          }).then(()=>{
            
            // Navigate to another screen
            window.location.href = "/home"; // Navigate to the home page
          })

          localStorage.removeItem("productTableData");
         
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Email not sent!',
            text: 'Email sending failed. Please try again.',
          });
        }
      });
    } else {
      console.log("No product data found in local storage.");
    }

    // You can include your printing logic here if needed
    // For example, trigger a print dialog or any other relevant action
    console.log("Printing receipt..."); // Replace with your print logic
  };

  return (
    <div className="container mt-4 bg-light print-container">
      <div className="top-section text-center">
        <h3 className="">Royal Masters Global System Solution Ltd</h3>

        <p className=" top-desc">
          <b>
            <i>
              Importer & Exporter, Distributor, Manufacturer, Suppliers <br />
              of General Goods & General Merchandise <br />
              <b>Email:</b> <br />
              royalmaster20@yahoo.co.uk, emymexx@yahoo.com
            </i> <br />
          </b> 
        </p>
        {/* Address */}
        <p className=" top-desc">
        <b>Head Office: </b>  #181 Bende Street, Port Harcourt, Rivers State. <br />
        <b>Tel: </b>  TEL: +234 (0) 803 338 5577, 802 602 2252 <br />
        {/* Branch Address */}
        <b>Branch Office: </b>  Shop #6 Afiwajoya Plaza, #25 St. Peter’s Road, <br /> Alaba Int’l Market, 
            Ojo Lagos, Lagos State.  <br />
        <b>Tel: </b>  TEL: +234 (0) 803 739 7559
        </p>
      </div>

      <h2 className=" text-center m-4">Sales Receipt</h2>

      {/* OFFICE ADDRESS(ES) */}

      {/* <div className="text-center add-container">
      
        <div className=" address">
          <h6>HEAD OFFICE:</h6>
          <p>
            #181 Bende Street, Port Harcourt, Rivers State. <br/>
            TEL: +234 (0) 803 338 5577, 802 602 2252
          </p>
        </div>

    
        <div className=" address">
          <h6>BRANCH:</h6>
          <p>
            Shop #6 Afiwajoya Plaza, #25 St. Peter’s Road, Alaba Int’l Market,
            Ojo Lagos, Lagos State.  <br/>
            TEL: +234 (0) 803 739 7559
          </p>
        </div>
      </div> */}

      <div className="mt-4">
        <p><b>To:</b> {to}</p>
        <table className="table table-bordered table-sm table-responsive">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Product Description</th>
              <th>Quantity</th>
              <th>Price per Unit (₦)</th>
              <th>Value (₦)</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.productName}</td>
                <td>{row.productDescription}</td>
                <td>{row.quantity}</td>
                <td>{formatToNaira(row.pricePerUnit)}</td>
                <td>{formatToNaira(row.value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="prices">
        <strong>Total Price:</strong> {formatToNaira(calculateTotalPrice())}
        <p className="mt-3"><b>Amount in words:</b> {amountInWords}</p>
      </div>

      {/* Signature */}
      <div className="signature">
        <img src={Signature} alt=" " width={150} />
        <p ><b><i>For: Royal Master Global System Ltd.</i></b></p>
      </div>
      <button
        className="btn btn-primary hide-on-print"
        onClick={handlePrintEmail}
      >
        Print Receipt
      </button>
    </div>
  );
}

export default PrintReceipt;

//     const handlePrintEmail = () => {
//       // Include your email sending logic here using SMTP.js
//       window.print();
//       const productTableData = JSON.parse(localStorage.getItem('productTableData'));

//        if(Array.isArray(tableData) && tableData.length > 0){

//         const emailBody = `
//         <div className="row">
//           <div className="col-12 col-md-12">
//             <table className="table table-bordered table-responsive">
//               <thead>
//                 <tr>
//                   <th>Product Name</th>
//                   <th>Product Description</th>
//                   <th>Quantity</th>
//                   <th>Price per Unit (₦)</th>
//                   <th>Value (₦)</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 ${tableData
//                   .map(
//                     (row, index) => `
//                     <tr key=${index}>
//                       <td>${row.productName}</td>
//                       <td>${row.productDescription}</td>
//                       <td>${row.quantity}</td>
//                       <td>${formatToNaira(row.pricePerUnit)}</td>
//                       <td>${formatToNaira(row.value)}</td>
//                     </tr>`
//                   )
//                   .join('')}
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <div>
//           <strong>Total Price:</strong> ${formatToNaira(calculateTotalPrice())}
//         </div>
//       `;

//     Email.send({
//       SecureToken: "1391a2e8-b8ba-458d-9837-5b3eac904d31",
//       To: 'danieldimkpa1@gmail.com',
//       From: 'danieldimkpa1@gmail.com',
//       Subject: 'Receipt Details',
//       Body: emailBody,
//     }).then((message) => {
// if (message === 'OK') {
//   // Email sent successfully
//   alert('Receipt email sent successfully.');

//   localStorage.removeItem('productTableData');
//   window.location.href = '/home';
// } else {
//   alert('Email sending failed. Please try again.');
// }
// });
//      }else {
//     console.log('No product data found in local storage.');
//     }

//       // You can include your printing logic here if needed
//       // For example, trigger a print dialog or any other relevant action
//       console.log('Printing receipt...'); // Replace with your print logic
//     };
