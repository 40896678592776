
/* eslint-disable */
import React from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import CreateReceipt from './components/Receipt/CreateReceipt';
import PrintReceipt from './components/Receipt/PrintReceipt';

const App = () => {
 return (
    <Container maxWidth="xl">
    <BrowserRouter>
   {/* <Navbar /> */}
     <Routes>
      <Route path='/' exact Component={Auth} />
      <Route path='/home' exact Component={()=> <Navigate to="/posts" />} />
      <Route path='/posts' exact Component={Home} />
      <Route path='/posts/search' exact Component={Home} />
      <Route path='/create-receipt' exact Component={CreateReceipt} />
      <Route path='/print-receipt' exact Component={PrintReceipt} />
     </Routes>
    </BrowserRouter>
    </Container>
  );
};

export default App;
