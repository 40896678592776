/* eslint-disable */
import React,{useState} from 'react';
import {Avatar, Button, Paper, Grid, Typography, Container, TextField} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import useStyles from './styles';
import Input from './Input ';
import { Navigate, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { database } from '../../FirebaseConfig';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const Auth = () => {
const classes = useStyles();
const [showPassword, setShowPassword]=useState(false);
const [isSignup, setIsSignUp] = useState(false)
const [forgotPassword, setForgotPassword] = useState(false);

// Function to map Firebase error codes to user-friendly messages
function getErrorMessageForFirebaseErrorCode(errorCode) {
  switch (errorCode) {
    case 'auth/invalid-login-credentials':
      return 'Invalid login credentials. Please check your email and password.';
    // Add more cases for other Firebase error codes as needed
    default:
      return 'An error occurred during authentication. Please try again later.';
  }
}



// Function to map firebase error codes for user-friendly messsage on reset password

function getErrorMessageForPasswordResetErrorCode(errorCode) {
  switch (errorCode) {
    case 'auth/expired-action-code':
      return 'The action code has expired. Please request a new password reset link.';
    case 'auth/invalid-action-code':
      return 'The action code is invalid. Please double-check the link or request a new one.';
    case 'auth/user-not-found':
      return `User not found. Please make sure you've entered the correct email address`;
    // Add more cases for other Firebase password reset error codes as needed
    default:
      return 'An error occurred during password reset. Please try again later.';
  }
}




const handleSubmit = (e, type) => {
  e.preventDefault();
  const email = e.target.email.value;
  const password = e.target.password.value;

  const AdminEmail =  email === 'bernardadaora1@gmail.com'

  if (type == "Sign Up") {
    createUserWithEmailAndPassword(database, email, password)
      .then((data) => {
        if(AdminEmail) {
           // Show admin access alert
           Swal.fire({
            icon: 'success',
            title: 'Admin Sign Up Successful',
            text: 'You have been signed up as an admin user.',
          });
          // Grant Access
          console.log(data, "authData");
          navigate("/home");
        } else{
           // Show error alert
        Swal.fire({
          icon: 'error',
          title: 'Admin Access only',
          text: err.message,
        });
        }
        
      })
      .catch((err) => {
           // Show error alert
           Swal.fire({
            icon: 'error',
            title: 'Admin Access only',
            text: err.message,
          });
        setLogin(true);
      });
  } else {
    signInWithEmailAndPassword(database, email, password)
      .then((data) => {
        if(AdminEmail) {
           // Show admin access alert
           Swal.fire({
            icon: 'success',
            title: 'Admin Sign Up Successful',
            text: 'You have been signed up as an admin user.',
          });
          // Grant Access
          console.log(data, "authData");
          navigate("/home");
        } else{
           // Show error alert
        Swal.fire({
          icon: 'error',
          title: 'Admin Access only',
          text: err.message,
        });
        }
      })
      .catch((err) => {
        const errorMessage = getErrorMessageForFirebaseErrorCode(err.code);
        // Show user-friendly error alert
        Swal.fire({
          icon: 'error',
          title: 'Sign Up Failed',
          text: errorMessage,
        });
      });
  }
};

// const handleChange = () => {

// }


// Function for reset Password


const handleResetPassword = async(e)=>{
  e.preventDefault()
  const emalVal = e.target.email.value;
  sendPasswordResetEmail(database,emalVal).then(data=>{
    Swal.fire({
      icon: 'success',
      title: 'Email Sent!!!',
      text: 'Check your email for a password reset link',
    }).then(()=>{
      location.reload()
    })
      // history("/")
  }).catch(err=>{
     const errorMessage2 = getErrorMessageForPasswordResetErrorCode(err.code)

      // Show user-friendly error alert
      Swal.fire({
        icon: 'error',
        title: 'Sign Up Failed',
        text: errorMessage2,
      });
  })
}


const switchMode = () => {[
  setIsSignUp(!isSignup),
]}

const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword)

const navigate = useNavigate();

const handleChange = ()=>{

}



const handleForgotPassword =()=>{
  setForgotPassword(true);
}

  return (
   <Container component="main" maxWidth="xs" >
    <Paper className={classes.paper} elevation={3}>
      {forgotPassword ? (
        <>
        <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
        
         <form className={classes.form} onSubmit={(e)=>handleResetPassword(e)}> 
              <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
             
              <Button type="submit" className={classes.resetButton} variant="contained" color="primary">Reset Password </Button >
             
         </form>
         </>
      ): (
        <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography variant='h5'>{isSignup ? 'Sign Up' : 'Sign In'}</Typography>
      <form className={classes.form} onSubmit={(e) => handleSubmit(e, isSignup ? "Sign Up" : "Sign In")}> 
        <Grid container spacing={2}>
          {
            isSignup && (
              <>
             
                <Input name='firstName' label='First Name' handleChange={handleChange}  half/>
                <Input name='lastName' label='Last Name' handleChange={handleChange}  half/>
              
              </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            {isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type='password' /> }
        </Grid>
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} >
          {isSignup ?"Sign Up" : 'Sign In'}
        </Button>
        <Grid container justifyContent='flex-end'>
        {!isSignup &&(<button className='m-auto btn' style={{color:'blue', textDecoration: 'underline'}} onClick={handleForgotPassword} >Forgot password.</button >)}
          <Grid item>
            <Button onClick={switchMode}>
              {isSignup ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}
            </Button>

           
          </Grid>
          </Grid>
      </form>
      </>
      )}
    </Paper>
   </Container>
  )
}

export default Auth