/* eslint-disable */

import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Banner from '../../images/rm2.png'


function CreateReceipt() {
  const [rows, setRows] = useState([
    {
      productName: '',
      productDescription: '',
      quantity: 0,
      pricePerUnit: 0,
      value: 0,
   
    },
  ]);


  const [to, setTo] = useState(''); // To field state
  const [amountInWords, setAmountInWords] = useState(''); // Amount in Words field state


  const addRow = () => {
    setRows([
      ...rows,
      {
        productName: '',
        productDescription: '',
        quantity: 0,
        pricePerUnit: 0,
        value: 0,
      },
    ]);
  };




  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = name === 'quantity' || name === 'pricePerUnit' ? parseFloat(value) : value;
    updatedRows[index].value = updatedRows[index].quantity * updatedRows[index].pricePerUnit;
    setRows(updatedRows);
  };

  const formatNaira = (value) => {
    return `₦${value.toLocaleString('en-NG')}`;
  };

  const calculateTotal = () => {
    return rows.reduce((total, row) => total + row.value, 0);
  };

  
  const createReceipt = () => {
    // Store the table data in localStorage
   //localStorage.setItem('productTableData', JSON.stringify(rows));
    const productTableData = {
      to,
      amountInWords,
      tableData: rows,
    };
    localStorage.setItem('productTableData', JSON.stringify(productTableData));
    Swal.fire({
      icon: 'success',
      title: 'Receipt Created ',
      text: 'Receipt has been created Successfully.',
    }).then(()=>{
      
      // Navigate to another screen
      window.location.href = '/print-receipt'; // Navigate to the receipt page
    })
  };

  return (
    <div className="container mt-4">

      {/* Top Section */}
      <div className='top-section2 text-center'>
      <img src={Banner} alt='banner' className='banner' />
      <p className='text-light mt-3'><b>CREATE CUSTOMER RECEIPT</b></p>
      </div>

      {/* To Customer */}
       <div className='to-customer m-3'>
        <label className='text-light'>Customer's Name:</label>
        <input type="text" value={to} onChange={(e) => setTo(e.target.value)} />
      </div>

     <div className='table-responsive'>

     <table className="table table-striped table-dark table-bordered table-sm ">
        <thead>
          <tr>
            {/* <th >Product Name</th> */}
            <th >Product Description</th>
            <th >Quantity</th>
            <th >Price per Unit</th>
            <th >Value</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {/* <td>
                <input type="text" name="productName" value={row.productName} onChange={(e) => handleInputChange(index, e)} />
              </td> */}
              <td>
                <textarea
                  name="productDescription"
                  value={row.productDescription}
                  onChange={(e) => handleInputChange(index, e)}
                ></textarea>
              </td>
              <td>
                <input type="number" name="quantity" value={row.quantity} onChange={(e) => handleInputChange(index, e)} />
              </td>
              <td>
                <input type="number" name="pricePerUnit" value={row.pricePerUnit} onChange={(e) => handleInputChange(index, e)} />
              </td>
              <td>{formatNaira(row.value)}</td>
            </tr>
          ))}
        </tbody>
      </table>

     </div>
      <button className="btn btn-primary" onClick={addRow}>
        Add More Row
      </button>

      <div className='total-amount'>
        <strong className='text-light'>Total Amount: {formatNaira(calculateTotal())}</strong>
      </div>

      <div className='in-words'>
        <p className='text-light'>Enter amount in words:</p>
        <textarea value={amountInWords} onChange={(e) => setAmountInWords(e.target.value)} cols={30} placeholder='Enter the total amount in words'></textarea>
      </div>
      <button className="btn btn-success" onClick={createReceipt}>Create Receipt</button>
    </div>
  );
}

export default CreateReceipt;
