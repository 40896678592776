/* eslint-disable */
import React from 'react';
import { AppBar, Button, Typography, Avatar, Toolbar, Grid } from '@material-ui/core';
import useStyles from './styles'
import Banner from '../../images/rm2.png';
import {Link} from 'react-router-dom';
import { database } from '../../FirebaseConfig';
import { signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";



const Navbar = () => {

    const classes = useStyles();

    const navigate=useNavigate();
    // const user = null;

    const handleSignOut = () =>{
      signOut(database).then(val=>{
          console.log(val,"val")
          navigate('/')
      })
  }
 
  return (
  <>
  <div className='container navbar-container'>
   <div className={classes.brandContainer}>
    <img src={Banner} alt='banner' width={200} />
    {/* <Typography component={Link} to='/' className={classes.heading} variant="h4" align="center">RMGSL</Typography> */}
        {/* <img className={classes.image} src={memories} alt="icon" height="60" /> */}
   </div>
       
       <div className='nav-btn'>
           
             <Button className={classes.btn} component={Link} to="/auth" variant="contained" color="secondary" onClick={handleSignOut} >Sign Out</Button>
             <Button className={classes.btn}  component={Link} to="/create-receipt" variant="contained" color="primary"  >Create Receipt</Button>
             
        </div> 

        </div>
    {/* {
        // IF A USER EXIST OR NOT
        user ? (
            // IF USER
            <div className={classes.profile}>
          <Avatar className={classes.purple} alt={user.result.name} src={user.result.imageUrl}>{user.result.charAt(0)}</Avatar>  
            <Typography className={classes.userName} variant='h6'>{user.result.name} </Typography>
            <Button variant='contained' className={classes.logout} color='secondary' >Logout</Button>
          </div>
        ) : (
            // IF NOT USER
             <Button component={Link} to="/auth" variant="contained" color="primary">Sign In</Button>
        )
    } */}
</>
  );
};

export default Navbar;
