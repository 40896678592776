/* eslint-disable */
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCMRmpIdvepVPpz3rfmZtzd36CZLa9eeyQ",
  authDomain: "inventory-auth-401904.firebaseapp.com",
  projectId: "inventory-auth-401904",
  storageBucket: "inventory-auth-401904.appspot.com",
  messagingSenderId: "768282993809",
  appId: "1:768282993809:web:55e8eebf10948f34f1cb30"
};

const app = initializeApp(firebaseConfig);
export const database = getAuth(app)