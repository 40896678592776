/* eslint-disable */
import React from 'react';
import { TextField, Grid, InputAdornment, IconButton } from '@material-ui/core';


import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Input  = ({name, half, handleChange, label, autoFocus, type, handleShowPassword}) => {
  return (
<Grid item xs={12} sm={half ? 6 : 12}>
<TextField
name={name}
onChange={handleChange}
variant='outlined'
required
fullWidth
label={label}
autoFocus={autoFocus}
type={type}
InputProps={name === 'password' && {
    endAdornment : (
        <InputAdornment popsition="end">
            <IconButton onClick={handleShowPassword}>
                {type === "password" ? <Visibility/> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    )
}}
/>
</Grid>
  )
}

export default Input 